












































import { Component, Vue } from 'vue-property-decorator'
import { CommonModule } from '@/store/modules/common'
@Component({
  name: 'question',
  components: {}
})
export default class Question extends Vue {
  active = ''

  questionList: any = [
    {
      id: 1,
      title: '如何兑换金豆？',
      content: '您好，选择您要兑换的金额，然后填写银行卡信息申请提现即可'
    },
    {
      id: 2,
      title: '金豆提现钱为什么还不到账?在哪里查看?',
      content:
        '您好，提现是24小时内到账的，国家法定节假日顺延，请注意查看银行卡的到账记录'
    },
    {
      id: 3,
      title: '私聊对方主动发消息，为什么回复后没金豆?',
      content: '可以在金豆清单核对是否收到金豆，在聊天界面中，金豆显示会有延迟'
    },
    {
      id: 4,
      title: '提现记录在哪里能看到?',
      content: '您好，可以在金豆清单 -> 金豆兑换中查看您的提现记录'
    },
    {
      id: 5,
      title: '为什么申请提现失败？',
      content:
        '有可能审核人员查询到你的金豆获取记录有异常，可能存在刷单行为，发现有此类情况，提现申请会失败，并且提现的金豆不予退还'
    }
    // {
    //   id: 6,
    //   title: '提现提示需要认证?',
    //   content: '在申请金豆提现前，您的账号需要实名认证都通过后才能提交申请'
    // }
  ]

  async created() {
    // 获取当前语言
  }

  // 当前语言
  get language() {
    return this.$i18n.locale
  }

  // 切换语言
  getLanguage(language: string) {
    this.$i18n.locale = language
  }

  // 返回
  handelBack() {
    this.$router.back()
  }
}
