
































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator'
import { appFunc_sendOpenImage, appFunc_RegisterFinishCb } from '@/utils/bridge'
@Component({
  name: 'UpLoadImage'
})
export default class UpLoadImage extends Vue {
  fileList: Array<any> = []

  @Prop()
  list!: Array<any>

  @Watch('list')
  onList(list: Array<any>) {
    this.fileList = list
  }

  @Emit('change')
  @Watch('fileList')
  onFileList(list: Array<any>) {
    return list
  }

  handelUpload() {
    appFunc_sendOpenImage()
    appFunc_RegisterFinishCb(this.handelFinish)
  }

  handelFinish(data: any) {
    if (/(iPhone|iOS)/i.test(navigator.userAgent)) {
      console.log(data, '////////')
      const pictureList = data
      console.log(pictureList, '返回图片路径')
      this.fileList = pictureList
    } else if (/(Android)/i.test(navigator.userAgent)) {
      console.log(data, '////////')
      const pictureList = JSON.parse(data)
      console.log(pictureList, '返回图片路径')
      this.fileList = pictureList
    }
  }
  deleteItem(index: number) {
    this.fileList.splice(index, 1)
  }
}
