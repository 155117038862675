



























































import { Component, Vue } from 'vue-property-decorator'
import { CommonModule } from '@/store/modules/common'
import { callAppFunc_getSomeParams, appFunc_senToken } from '@/utils/bridge'
import { Toast } from 'vant'
import { submitFeedback } from '@/api'
import UpLoadImage from './components/UpLoadImage.vue'
@Component({
  name: 'Problem',
  components: { UpLoadImage }
})
export default class Problem extends Vue {
  // 表单信息
  form = {
    // 提交用户的手机号
    mobile: '',
    // 意见文本
    text: '',
    androidVersion: '',
    equipmentNumber: '',
    // 被投诉人手机号
    respondent: ''
  }
  fileList: Array<any> = []
  loading = false
  onChange(list: Array<any>) {
    this.fileList = list
  }
  created() {
    this.getToken()
    this.getSomeData()
    // 获取当前语言
  }

  async getSomeData() {
    const res = await callAppFunc_getSomeParams()
    console.log(res, 'getSomeData', '////////////////////////////')
    this.form.mobile = res.mobile
    this.form.respondent = res.respondent
    this.form.androidVersion = res.sysversion
    this.form.equipmentNumber = res.devicemodel
  }

  async getToken() {
    const res = await appFunc_senToken()
    console.log(res, '获取token')
    CommonModule.setUserId(res.id)
    CommonModule.setToken(res.tokenForJs)
  }

  // 当前语言
  get language() {
    return this.$i18n.locale
  }

  // 切换语言
  getLanguage(language: string) {
    this.$i18n.locale = language
  }

  async handelSubmit() {
    if (!this.form.text) {
      return
    }
    const { code, message } = await submitFeedback({
      mobile: this.form.mobile,
      text: this.form.text,
      equipmentNumber: this.form.equipmentNumber,
      androidVersion: this.form.androidVersion,
      respondent: this.form.respondent
    })
    this.loading = true
    if (code == 1001) {
      this.loading = false
      this.form.text = ''
      this.form.mobile = ''
      Toast(this.$t('提交成功'))
    } else {
      this.loading = false
      Toast(message)
    }
  }

  //   返回常见问题反馈
  handelback() {
    this.$router.push({
      path: '/feedback',
      query: {
        language: this.language
      }
    })
  }
}
